import { APIFilter } from '@/utils/api'

export default {
  async selectLoadPage (Vue, idpresupuestocli) {
    const apiCalls = []
    const apiFilterPresupuesto = new APIFilter()
      .addExact('idpresupuestocli', idpresupuestocli)
    apiCalls.push({ name: 'selectPresupuestocli', method: 'presupuestocli.select', params: { filter: apiFilterPresupuesto } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectLookups (Vue) {
    const apiFilterEstadoActivo = new APIFilter()
      .addGT('estado', 0)
    let apiCalls = []
    apiCalls.push({ name: 'selectPeriodoCuota', method: 'periodoCuota.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectConceptoCuota', method: 'conceptoCuota.select', params: { filter: apiFilterEstadoActivo } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectPresupuestoCuota (Vue, filter, search, sorter, page, idpresupuestocli) {
    const apiFilter = new APIFilter()
      .addGT('estado', 0)
      .addExact('idpresupuestocli', idpresupuestocli)
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.presupuestoCuota.searchFilter(search))
    }
    if (filter.periodoCuota.value && filter.periodoCuota.value.length > 0) {
      apiFilter.addIn(filter.periodoCuota.field, filter.periodoCuota.value)
    }
    if (filter.conceptoCuota.value && filter.conceptoCuota.value.length > 0) {
      apiFilter.addIn(filter.conceptoCuota.field, filter.conceptoCuota.value)
    }
    const resp = await Vue.$api.call(
      'presupuestoCuota.select',
      {
        page,
        filter: apiFilter,
        sorter,
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectPresupuestoCuotaRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idpresupuesto_cuota', pks)
    const resp = await Vue.$api.call('presupuestoCuota.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async deletePresupuestoCuota (Vue, idpresupuestoCuota) {
    await Vue.$api.call('presupuestoCuota.delete', { idpresupuesto_cuota: idpresupuestoCuota })
  },
}
